import { RouteLoadFunc, useNavigate } from "@solidjs/router"

export const preventOut: RouteLoadFunc = function () {
    const token = localStorage.getItem("accessToken")
    const navigate = useNavigate()

    if (token) {
        return navigate("/")
    }
}
