import { Route, Router } from "@solidjs/router"
import { ErrorBoundary, lazy } from "solid-js"
import { render } from "solid-js/web"
import { loadUser } from "./guards/load-user"
import { preventOut } from "./guards/prevent-out"
import { Toaster } from "solid-toast"
import { QueryClient, QueryClientProvider } from "@tanstack/solid-query"
import { sendError } from "./utils/sentry"

import "./style.scss"
import "tippy.js/dist/tippy.css"

// const Inventory = lazy(() => import("./pages/item-inventory"))
// const Register = lazy(() => import("./pages/register"))
// const WarehouseCustomer = lazy(() => import("./pages/warehouse-customer"))
const ProblemInventories = lazy(() => import("./features/problem-inventories/problem-inventories"))
const InventoryCentral = lazy(() => import("./features/inventory-central/inventory-central"))
const Layout = lazy(() => import("./layout"))
const Unfinished = lazy(() => import("./pages/unfinished"))
const Home = lazy(() => import("./pages/home"))
const Login = lazy(() => import("./pages/login"))
const Inbound = lazy(() => import("./pages/inbound"))
const Outbound = lazy(() => import("./pages/outbound"))
const Settings = lazy(() => import("./pages/settings"))
const Invoices = lazy(() => import("./pages/invoices"))
const PrintBarcode = lazy(() => import("./pages/print-barcode"))
const PrintBarcodeV2 = lazy(() => import("./pages/print-barcode-v2"))
const PrintReceipt = lazy(() => import("./features/print-receipt/print-receipt"))

const root = document.querySelector("main")
const queryClient = new QueryClient({
    defaultOptions: {
        mutations: {
            onError(error) {
                sendError(error)
            },
        },
        queries: {
            throwOnError(error) {
                sendError(error)
                return false
            },
        },
    },
})

render(
    () => (
        <QueryClientProvider client={queryClient}>
            <Router>
                <Route load={loadUser} path="/" component={Layout}>
                    {/* <Route path="/warehouse-customer" component={WarehouseCustomer} /> */}
                    {/* <Route path="/inventory" component={Inventory} /> */}
                    {/* <Route path="/transactions" component={Unfinished} /> */}
                    <Route path="/" component={Home} />
                    <Route path="/inbound" component={Inbound} />
                    <Route path="/outbound" component={Outbound} />
                    <Route path="/notifications" component={Unfinished} />
                    <Route path="/inventory" component={InventoryCentral} />
                    <Route path="/invoices" component={Invoices} />
                    <Route path="/settings" component={Settings} />
                    <Route path="/reports" component={Unfinished} />
                    <Route path="/problem-inventories" component={ProblemInventories} />
                    <Route path="/warehouse-insight" component={Unfinished} />
                </Route>

                <Route load={loadUser} path="/print-barcode/:from/:ids" component={PrintBarcode} />
                <Route load={loadUser} path="/print-barcode/v2/:id" component={PrintBarcodeV2} />
                <Route load={loadUser} path="/print-receipt" component={PrintReceipt} />

                <Route load={preventOut}>
                    <Route path="/login" component={Login} />
                </Route>
            </Router>
            <ErrorBoundary
                fallback={(err: any) => {
                    console.warn(err)
                    console.log("Toaster Error After pnpm-lock.yaml change npm + map files")
                    return <></>
                }}
            >
                <Toaster
                    toastOptions={{
                        className: "!p-1 !px-3 !rounded-md",
                        position: "top-center",
                    }}
                />
            </ErrorBoundary>
        </QueryClientProvider>
    ),
    root!
)
