import axios from "axios"

const client = axios.create({
    baseURL: import.meta.env.VITE_BASE_URL,
})

const clientWithAuth = axios.create({
    baseURL: import.meta.env.VITE_BASE_URL,
})

clientWithAuth.interceptors.request.use(function (config) {
    let token = localStorage.getItem("accessToken") ?? ""
    token = "Bearer " + token

    config.headers.Authorization = token

    return config
})

export { clientWithAuth, client }
