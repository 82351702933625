import { cache, useNavigate } from "@solidjs/router"
import { getUserInfo } from "../api/get-user-info"
import { useUserInfo } from "../stores/use-user-info"

const loader = cache(async function (token: string) {
    return await getUserInfo(token)
}, "user-info")

export const loadUser = async function () {
    const token = localStorage.getItem("accessToken")
    const navigate = useNavigate()

    if (!token) {
        return navigate("/login")
    }

    try {
        const [, setUser] = useUserInfo()
        const user = await loader(token)

        setUser("email", user.data.email)
        setUser("picture", user.data.profile_picture)
        setUser("username", user.data.username)
        setUser("name", user.data.name)

        return user
    } catch {
        localStorage.removeItem("accessToken")
        return navigate("/login")
    }
}
