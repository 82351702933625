import * as Sentry from "@sentry/solid"
import { Client, ClientOptions, BaseTransportOptions, Scope } from "@sentry/types"
import { DEV } from "solid-js"

let sentry: Client<ClientOptions<BaseTransportOptions>> | undefined

function sendError(err: any, hint?: Sentry.EventHint, scope?: Scope) {
    if (!sentry) {
        sentry = Sentry.init({
            dsn: "https://e1ec70ff5da9c14b463dde36f90a8601@o4507910777929728.ingest.de.sentry.io/4507910799360080",
            integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        })
    }
    if (!DEV) sentry!.captureException(err, hint, scope)
}

function sendErrorMessage(message: string, level?: Sentry.SeverityLevel, hint?: Sentry.EventHint) {
    if (!sentry) {
        sentry = Sentry.init({
            dsn: "https://e1ec70ff5da9c14b463dde36f90a8601@o4507910777929728.ingest.de.sentry.io/4507910799360080",
            integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        })
    }
    if (!DEV) sentry!.captureMessage(message, level, hint)
}

export { sentry, sendError, sendErrorMessage }
