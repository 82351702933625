import { createStore } from "solid-js/store"

export type UserInfo = {
    username?: string
    email?: string
    picture?: string
    name?: string
}

const userStore = createStore<UserInfo>()

export function useUserInfo() {
    return userStore
}
