import { client } from "./client"

export type GetUserInfoResponse = {
    message: string
    validation_message: {}
    data: {
        id: number
        name: string
        profile_picture: string
        username: string
        email: string
        is_suspend: boolean
        is_root: boolean
        created: string
    }
}

export const getUserInfo = async function (token: string) {
    token = "Bearer " + token

    const headers = { Authorization: token }
    const params = { from: "warehouse" }
    const res = await client.get("/v1/users/login", { headers, params })

    return res.data as GetUserInfoResponse
}
